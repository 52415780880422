import { createAction, props } from '@ngrx/store';
import { TaskState } from './task.reducers';

export const setTaskState = createAction('[Task Management] Set Task State', props<TaskState>());

export const refreshTaskData = createAction('[Task List Page] Refresh Task List');

export const refreshAppointmentData = createAction('[Appointment Page] Refresh Appointment Data');

export const refreshTaskStatistic = createAction('[Task Management] Refresh Task Statistic');

export const updateTask = createAction('[Task List Page] Update Task', props<{ id: string }>());

export const updateAppointment = createAction('[Appointment Page] Update Appointment', props<{ id: string }>());
