export enum ColorEnum {
  GRAY = 'GRAY',
  RED = 'RED',
  ORANGE = 'ORANGE',
  GREEN = 'GREEN',
  BLUE_SKY = 'BLUE_SKY',
  BLUE = 'BLUE',
  PURPLE = 'PURPLE',
  PINK = 'PINK'
}
